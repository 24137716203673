@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.uploadWrapper
{
    display:        flex;
    flex-direction: row;
    flex-wrap:      wrap;

    .imageContainer
    {
        display:         flex;
        height:          213px;
        width:           213px;
        border-radius:   4px;
        margin-right:    15px;
        margin-top:      15px;
        overflow:        hidden;
        background-size: cover;
        align-items:     center;
        justify-content: center;
        font-size:       36px;
        color:           $colorWhite;

        img
        {
            height:     213px;
            width:      213px;
            object-fit: cover;
        }

        svg
        {
            position: absolute;
            cursor:   pointer;

            &.clickThrough
            {
                pointer-events: none;
            }
        }
    }

    input
    {
        cursor: pointer !important;
    }

    .addImageContainer
    {
        cursor:          pointer !important;
        border:          solid 2px $colorBlueDarkGray;
        font-size:       36px;
        color:           $colorWhite;
        align-items:     center;
        justify-content: center;
    }

    .fileInputContainer
    {
        position: relative;
    }

    .fileInputContainer input
    {
        position: absolute;
        z-index:  2;
        opacity:  0;
        width:    100%;
        height:   100%;
    }
}
