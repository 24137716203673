@import '../../../styles/breakpoints';

.versionNumberWrapper
{
    display:        flex;
    flex-direction: column;
    align-items:    center;

    @media (min-width: $breakpointMedium)
    {
        align-items: flex-end;
    }
}

.versionNumber
{
}