@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.printHeaderContainer
{
    display:        flex;
    flex-direction: row;
    border-bottom:  1px solid $colorLightGray3;
    padding-bottom: 20px;
    margin-bottom:  30px;

    .leftHeaderContainer
    {
        flex-grow:  1;
        flex-basis: 0;

        h2
        {
            margin-bottom: 20px;
        }
    }

    .rightHeaderContainer
    {
        flex-grow:       2;
        flex-basis:      0;
        display:         flex;
        align-items:     flex-end;
        justify-content: flex-end;
    }

    .printHeaderLogo
    {
        height: 70px;
    }
}