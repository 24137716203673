@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.logo
{
    display:         flex;
    justify-content: center;
    align-items:     center;
    height:          auto;
    position:        relative;

    img
    {
        width: 150px;
    }
}