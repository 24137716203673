@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.white
{
    color: $colorWhite;
}

.text
{
    opacity:        0.8;
    font-size:      16px;
    font-weight:    normal;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.5;
    letter-spacing: 0.4px;
    text-align:     center;
    color:          #ffffff;
    padding-bottom: 40px;

    &.noPaddingBottom
    {
        padding-bottom: 0;
    }
}