@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.colorButton
{
    border-radius:   4px;
    font-size:       11px;
    font-weight:     bold;
    padding:         13px 24px;
    cursor:          pointer;
    transition:      transform 0.1s linear;
    text-decoration: none;
    user-select:     none;
    text-transform:  uppercase;
    max-width:       128px;
    text-align:      center;

    @media (min-width: $breakpointSmallMin)
    {
        font-size: 14px;
    }

    &:hover
    {
        transform: scale(1.04);
    }

    > span
    {
        margin: 0 6px 0 0;
        width:  auto;
    }
}

.colorButton + .colorButton
{
    margin-left: 5px;

    @media (min-width: $breakpointSmall)
    {
        margin-left: 8px;
    }
}

.colorButtonGray,
.colorButtonGraySmall,
.colorButtonGraySlim,
.colorButtonGraySmallFullWidth,
{
    background: $colorBlack;
    color:      $colorWhite;
}

.colorButtonYellow,
.colorButtonYellowSmall
{
    background: $colorYellow;
    color:      $colorDarkGray;
}

.colorButtonGraySmall,
.colorButtonGraySlim,
.colorButtonGraySmallFullWidth,
.colorButtonYellowSmall
{
    padding:       22px 11px;
    border-radius: 6px;

    @media (min-width: $breakpointSmall)
    {
        padding: 22px 7px;
    }
}

.colorButtonGraySlim
{
    padding: 5px 11px;
}

.colorButtonGraySmallFullWidth
{
    flex-grow: 1;
}