@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.container
{
    display:    flex;
    flex-grow:  1;
    min-height: 30px;

    .value
    {
        font-size:   16px;
        font-family: $fontSourceRoboto;
    }

    .labelContainer
    {
        flex-grow:   1.2;
        flex-basis:  0;
        display:     flex;
        padding-top: 2px;

        .value
        {
            color: $colorLightGray2;
        }
    }

    .valueContainer
    {
        flex-grow:   1.8;
        flex-basis:  0;
        align-items: center;
        padding:     5px 15px;
        font-weight: bold;

        &.grayBackground
        {
            font-weight:      normal;
            background-color: $colorLightGray4 !important;
            border-bottom:    1px solid $colorLightGray3;
        }
    }

    &.small
    {
        min-height: initial;

        .valueContainer
        {
            padding:     2px 0;
            font-weight: normal;
        }
    }
}