@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.alertBox
{
    padding:       12px 32px 12px 53px;
    border-radius: 4px;
    font-size:     15px;
    border-width:  1px;
    border-style:  solid;
    margin:        0 0 20px 0;
    position:      relative;
    border-color:  $colorYellow;
    background:    $colorYellow;
    color:         $colorBlack;

    svg
    {
        *
        {
            fill: $colorBlack !important;
        }
    }

    .alertBoxIcon
    {
        background: $colorBlack;

        svg
        {
            *
            {
                fill: $colorYellow !important;
            }
        }
    }
}

.alertBoxCloseButtonWrapper
{
    position: absolute;
    top:      10px;
    right:    10px;
}

.alertBoxIcon
{
    $size:           20px;

    width:           $size;
    height:          $size;
    border-radius:   $size / 2;
    position:        absolute;
    top:             50%;
    left:            18px;
    margin:          -($size / 2) 0 0 0;
    display:         flex;
    justify-content: center;
    align-items:     center;
}