@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.icon
{
    background-size: contain;
    width:           100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
    height:          auto;
    position:        relative;

    svg
    {
        *
        {
            transition: fill 0.1s linear;
            fill:       $colorLightGray;
        }
    }
}