@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.dateInputWrapper
{
    display:        flex;
    flex-direction: row;
    width:          100%;
    box-sizing:     border-box;
    min-height:     40px;
    position:       relative;
    margin:         6px 0;

    @media (min-width: $breakpointMedium)
    {
        margin: 6px 5px;
    }
}

.calendarIcon
{
    display:     flex;
    height:      100%;
    align-items: center;
    position:    absolute;
    right:       10px;
}

:global .react-datepicker__header
{
    background-color: $colorWhite !important;
}

:global .react-datepicker-wrapper
{
    flex:    1;
    display: flex;
}

:global .react-datepicker__input-container
{
    flex:       1;
    display:    flex !important;
    box-sizing: border-box;
    width:      100%;
    height:     40px;

    input
    {
        border-radius:    6px;
        background-color: $colorBlueDarkGray !important;
        width:            100%;
        color:            $colorLightGray2;
        font-size:        16px;
        padding-left:     12px;
        padding-right:    20px;
        transition:       border-color 0.1s linear;
        border:           1px solid $colorBlueDarkGray;

        &:hover,
        &:focus
        {
            border-color: $colorLightGray;
            outline:      none;
        }
    }
}

:global .react-datepicker__day
{
    outline: none !important;
    color:   $colorWhite !important;
}

:global .react-datepicker__navigation
{
    outline: none !important;
}

:global .react-datepicker__navigation--previous,
:global .react-datepicker__navigation--next
{
    transition: border-color 0.1s linear;
}

:global .react-datepicker__navigation--previous
{
    border-right-color: $colorLightGray !important;
}

:global .react-datepicker__navigation--next
{
    border-left-color: $colorLightGray !important;
}

:global .react-datepicker__navigation--previous:hover
{
    border-right-color: $colorLightGray2 !important;
}

:global .react-datepicker__navigation--next:hover
{
    border-left-color: $colorLightGray2 !important;
}

:global .react-datepicker__day--selected,
:global .react-datepicker__time-list-item--selected,
:global .react-datepicker__day--in-selecting-range,
:global .react-datepicker__day--in-range,
:global .react-datepicker__month-text--selected,
:global .react-datepicker__month-text--in-selecting-range,
:global .react-datepicker__month-text--in-range,
:global .react-datepicker__quarter-text--selected,
:global .react-datepicker__quarter-text--in-selecting-range,
:global .react-datepicker__quarter-text--in-range,
:global .react-datepicker__day--keyboard-selected,
:global .react-datepicker__month-text--keyboard-selected,
:global .react-datepicker__quarter-text--keyboard-selected,
:global .react-datepicker__day--keyboard-selected:hover,
:global .react-datepicker__month-text--keyboard-selected:hover,
:global .react-datepicker__quarter-text--keyboard-selected:hover
{
    background-color: $colorYellow !important;
    color:            $colorBlack !important;
}

:global .react-datepicker__day:hover
{
    background-color: $colorGreenYellow !important;
    color:            $colorWhite !important;
}

:global .react-datepicker__time-list-item:hover
{
    background-color: $colorGreenYellow !important;
    color:            $colorWhite !important;
}

:global .react-datepicker__week-number
{
    color: $colorLightGray !important;
}

:global .react-datepicker .react-datepicker__day--selected
{
}

:global .react-datepicker .react-datepicker__current-month,
:global .react-datepicker .react-datepicker__day-name
{
    color: $colorWhite !important;
}

:global .react-datepicker,
:global .react-datepicker .react-datepicker__header,
:global .react-datepicker__time-box,
:global .react-datepicker-time__header
{
    background-color: $colorBlueDarkGray !important;
    color:            $colorWhite !important;
}

:global .react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time
{
    right: 90px;
}