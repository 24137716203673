@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.container
{
    display:        flex;
    margin-bottom:  40px;
    flex-direction: column;

    .fileUpload {
        border: none;
        margin-left: 7px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        background: #f9f700;
        color: #1a1b1f;
        cursor: pointer;
        border-radius: 4px;
        font-weight: bold;
        font-size: 14px;
        height: auto;
        transition: -webkit-transform 0.1s linear;
        transition: transform 0.1s linear;
        transition: transform 0.1s linear, -webkit-transform 0.1s linear;
        text-decoration: none;
        text-transform: uppercase;
        max-width: 128px;
        &:hover
        {
            transform: scale(1.04);
        }
    }

    @media (min-width: $breakpointSmallMin)
    {
        margin-bottom:  68px;
        flex-direction: row;
        align-items:    center;
    }

    &.containerNoMarginBottom
    {
        margin-bottom: 0 !important;
    }

    h2
    {
        color: $colorWhite;
    }
}

.headerContent
{
    flex-direction: column;
    display:        flex;
    flex-grow:      6;

    h2
    {
        color:     $colorWhite;
        font-size: 22px;

        @media (min-width: $breakpointMedium)
        {
            font-size: 24px;
        }
    }

    .reportDate
    {
        color:         $colorLightGray;
        font-size:     14px;
        margin-bottom: 14px;
    }

    @media (min-width: $breakpointSmallMin)
    {
        margin-bottom: 0;
    }
}

.headerOptions
{
    display:         flex;
    flex-grow:       2;
    justify-content: flex-start;
    margin-top:      10px;

    @media (min-width: $breakpointSmallMin)
    {
        justify-content: flex-end;
        margin-top:      0;
        margin-left:     20px;
    }
}

.uploadInput {
    display: none;
}