@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/colors';

*
{
    margin:  0;
    padding: 0;
}

body
{
    margin:                  0;
    padding:                 0;
    font-family:             $fontSourceGeneralBody;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    height:                  100%;
    background-color:        $colorDarkGray;
}

a
{
    text-decoration: none;
    color:           $colorYellow;
}

body > #root > div
{
    height: 100vh;
}

code
{
    font-family: $fontSourceCourierNew;
}

.contentView
{
    flex-direction: column;
    display:        flex;
    padding-bottom: 200px;
    flex:           1;
}

@media print
{
    body
    {
        display:      table;
        table-layout: fixed;
        width:        100%;
        height:       auto;
        background:   none;
    }
}

@media print
{
    body
    {
        -webkit-print-color-adjust: exact;
    }
}

.printPreview
{
    display:          block !important;
    background-color: white;
    max-width:        900px;
    min-width:        900px;
    min-height:       2000px !important;
    padding:          1.5cm;
    margin:           auto;
    page-break-after: always !important;

    .printPageBreak
    {
        page-break-before: always;
    }

    @media print
    {
        padding:    0 !important;
        min-height: 100% !important;
    }

    .printValueContainer
    {
        margin-bottom: 30px;

        h3
        {
            margin-bottom: 10px;
        }
    }

    .printPreviewImageRow
    {
        page-break-inside: avoid;
        display:           flex;
        justify-content:   flex-start;
        flex-direction:    row !important;
        flex-wrap:         wrap;
        align-content:     flex-start;
    }

    .printPreviewValuesRow
    {
        display:         flex;
        justify-content: space-between;
        flex-direction:  row !important;
        flex-wrap:       wrap;
        align-content:   flex-start;

        .printPreviewColumn
        {
            flex-grow:       0.48;
            flex-basis:      0;
            justify-content: center;
            align-content:   center;
            justify-items:   center;
            align-items:     center;
        }
    }

    .printPreviewValuesRowPageBreak
    {
        h3
        {
            page-break-before: auto;
        }

        page-break-before: auto;
        page-break-inside: avoid;
    }

}

.contentViewWithPadding
{
    @media (min-width: $breakpointMedium)
    {
        padding-top:    111px;
        padding-bottom: 150px;
    }

    position:        relative;
    padding-top:     50px;
    flex-direction:  column;
    display:         flex;
    justify-content: center;
    flex:            1;
}

.formContentView
{
    display:        flex;
    flex-direction: column;
    margin:         0 auto;
    max-width:      725px;
    align-self:     center;
    margin-top:     30px;
    padding-bottom: 20px;

    @media (min-width: $breakpointSmall)
    {
        max-width:     initial;
        align-self:    normal;
        padding-left:  13px;
        padding-right: 13px;
    }

    @media (min-width: $breakpointMedium)
    {
        margin-top:    60px;
        width:         100%;
        max-width:     540px;
        align-self:    center;
        padding-left:  0;
        padding-right: 0;
    }

    @media (min-width: $breakpointLarge)
    {
        max-width: 725px;
    }
}

.appContent
{
    > div
    {
        min-height:     100vh;
        display:        flex;
        flex-direction: column;
    }
}

.alignItemsFlexEnd
{
    align-items: flex-end;
}

html
{
    height: 100%;
}

input[type=submit]
{
    display: none;
}

.textAlignLeft
{
    text-align: left !important;
}

h4
{
    color:       $colorLightGray;
    font-size:   16px;
    font-weight: normal;
}

.tableContainer
{
    $marginBottom: 40px;
    margin:        0 15px $marginBottom;
    overflow-x:    auto;

    @media (min-width: $breakpointMedium)
    {
        margin: 0 50px $marginBottom;
    }

    @media (min-width: $breakpointExtraLarge)
    {
        margin: 0 150px $marginBottom;
    }

    table
    {
        max-width: 1500px;
    }

    table
    {
        border-spacing: 0;

        th
        {
            color:            $colorYellow;
            padding:          14px 24px;
            font-size:        20px;
            font-family:      $fontSourceGeneralBody;
            font-weight:      normal;
            background-color: $colorDarkGray2;
        }

        th.fullWidth
        {
            width: 100%;
        }

        td
        {
            font-size:        20px;
            color:            $colorWhite;
            padding:          14px 24px;
            text-align:       center;
            background-color: transparent;
        }
    }
}