@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.badgeContainer
{
    border-radius:    22px;
    border:           solid 4px $colorDarkGray;
    height:           24px;
    min-width:        22px;
    position:         absolute;
    background-color: $colorRed;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    top:              -18px;
    right:            20px;
    z-index:          1337;
    padding:          4px;
    user-select:      none;

    @media (min-width: $breakpointMedium)
    {
        right: -19px;
    }
}

.badgeCount
{
    color:       $colorWhite;
    font-size:   16px;
    font-weight: bold;
    font-family: $fontSourceRoboto;
}
