@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.headline
{
    margin:         50px auto;
    font-stretch:   normal;
    font-style:     normal;
    letter-spacing: -0.5px;
}

.whiteBigCentered
{
    color:       #ffffff;
    font-size:   27px;
    font-weight: 900;
    text-align:  center;

    @media (min-width: $breakpointSmall)
    {
        font-size: 31px;
    }

    @media (min-width: $breakpointMedium)
    {
        font-size: 64px;
    }
}

.whiteSmall
{
    font-size:   28px;
    font-weight: bold;
    color:       $colorWhite;
    line-height: 1.29;
    margin:      0 0 70px 0;
}