@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

$size: 40px;

.container
{
    position:      relative;
    margin-bottom: 50px;

    .searchField
    {
        height:        $size;
        width:         100%;
        padding:       0 $size 0 10px;
        border:        0;
        margin:        0;
        box-sizing:    border-box;
        border-radius: 3px;
    }
}