// @formatter:off
$colorBlack:        #0d0e11;
$colorBlue:         #3b55e6;
$colorDarkBlack:    #000000;

$colorGray1:        #cccccc;
$colorLightGray:    #5d6c76;
$colorLightGray2:   #9fafb5;
$colorLightGray3:   #dcdcdc;
$colorLightGray4:   #f6f6f6;

$colorBlueDarkGray: #212428;
$colorDarkGray:     #1a1b1f;
$colorDarkGray2:    #1d1e23;
$colorDarkGray3:    #24262C;
$colorDarkGray4:    #292b35;
$colorDarkGray90:   rgba(26, 27, 31, 0.9);

$colorGreenYellow:  #323400;
$colorRed:          #ff0039;
$colorWhite50:      rgba(255, 255, 255, 0.5);
$colorWhite80:      rgba(255, 255, 255, 0.8);
$colorWhite:        #ffffff;
$colorYellow:       #f9f700;
$colorYellow50:     rgba(249, 247, 0, 0.5);
$colorYellow80:     rgba(249, 247, 0, 0.8);
// @formatter:on

:export
{
    colorBlack:        $colorBlack;
    colorBlue:         $colorBlue;
    colorGray:         $colorGray1;
    colorLightGray:    $colorLightGray;
    colorLightGray2:   $colorLightGray2;
    colorBlueDarkGray: $colorBlueDarkGray;
    colorDarkGray:     $colorDarkGray;
    colorDarkGray2:    $colorDarkGray2;
    colorDarkGray90:   $colorDarkGray90;
    colorGreenYellow:  $colorGreenYellow;
    colorRed:          $colorRed;
    colorWhite50:      $colorWhite50;
    colorWhite80:      $colorWhite80;
    colorWhite:        $colorWhite;
    colorYellow:       $colorYellow;
    colorYellow50:     $colorYellow50;
    colorYellow80:     $colorYellow80;
}