@import '../../../styles/colors';
@import '../../../styles/fonts';

table
{
    tbody.colored
    {
        tr
        {
            &:nth-child(3n)
            {
                background-color: $colorDarkGray4;
            }

            &:nth-child(3n+1)
            {
                background-color: $colorDarkGray3;
            }

            &:nth-child(3n+2)
            {
                background-color: $colorDarkGray2;
            }
        }
    }

    tr.descriptionHeader th
    {
        color:            $colorLightGray;
        padding:          10px 20px;
        font-size:        16px;
        font-family:      $fontSourceGeneralBody;
        font-weight:      normal;
        background-color: transparent;
    }

    .highlightedTitle
    {
        color: $colorYellow50
    }
}