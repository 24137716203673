@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.loadingOverlay
{
    display:    none;
    top:        0;
    left:       0;
    background: $colorDarkGray90;
    z-index:    $zIndexLoadingOverlay;
    position:   fixed;
    width:      100%;
    height:     100%;
    overflow:   hidden;

    .loadingOverlayPulse
    {
        margin: auto;
    }
}

@keyframes pulse_animation
{
    0%
    {
        box-shadow: 0 0 0 0 $colorYellow80;
    }
    100%
    {
        box-shadow: 0 0 0 200px transparent
    }
}

.loadingOverlayVisible
{
    display: flex;

    .loadingOverlayPulse
    {
        span
        {
            position:      absolute;
            margin-top:    -25px;
            margin-left:   -25px;
            width:         50px;
            height:        50px;
            border-radius: 50%;
            background:    $colorYellow;
            animation:     pulse_animation 1.3s infinite;

            &:nth-child(2)
            {
                animation-delay: 0.3s;
            }
        }
    }
}