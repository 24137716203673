@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.container
{
    flex-grow:       1;
    flex-basis:      0;
    display:         flex;
    align-items:     center;
    flex-flow:       row wrap;
    justify-content: center;

    @media (min-width: $breakpointMedium)
    {
        justify-content: flex-start;
        margin-top:      0;
    }
}