@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.container
{
    display:      flex;
    align-items:  center;
    padding-left: 0;

    @media (min-width: $breakpointSmall)
    {
        padding-left: 16px;
    }

    span
    {
        font-size:   16px;
        font-family: $fontSourceRoboto;
        color:       $colorLightGray2;
    }
}