@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.printImage
{
    display:          block;
    page-break-after: always;
    width:            100%;
    margin:           10px auto;
}

@media print
{
    .printImage
    {
        margin:            auto;
        display:           block;
        page-break-after:  always;
        width:             auto;
        max-width:         100%;
        max-height:        297mm !important;
        page-break-before: auto;
        page-break-inside: avoid;
    }
}