@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.centerContainer
{
    max-width:  100%;
    align-self: center;
    padding:    0 10px;

    @media (min-width: $breakpointMedium)
    {
        max-width: 700px;
    }
}

.centerContainerCenterContent
{
    display:         flex;
    justify-content: center;
    flex-direction:  column;
    text-align:      center;
}