@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.container
{
    display: flex;
    padding: 10px;

    @media (min-width: $breakpointMediumMin)
    {
        padding: 20px;
    }
}

.footerLink
{
    color:           $colorWhite;
    text-decoration: none;
    font-weight:     bold;
    font-size:       12px;
    white-space:     nowrap;
}