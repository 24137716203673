@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.container
{
    position:         relative;
    bottom:           0;
    left:             0;
    right:            0;
    background-color: $colorBlack;
    padding:          20px 30px 30px;

    @media (min-width: $breakpointMedium)
    {
        display:        flex;
        flex-direction: row;
        position:       fixed;
        bottom:         0;
        left:           0;
        padding:        40px 30px;
    }

    @media (min-width: $breakpointLarge)
    {
        padding: 40px 130px;
    }
}

.footerLogoContainer
{
    display:         flex;
    justify-content: center;
    align-items:     center;
    margin-top:      5px;
    margin-bottom:   10px;

    @media (min-width: $breakpointMedium)
    {
        margin-top:    0;
        margin-bottom: 0;
    }

    .footerLogo
    {
        position: relative;
        padding:  10px 30px;
    }
}

.footerLogo
{
    height: 51px;
}

.footerRightContainer
{
    display:         flex;
    flex-direction:  column;
    flex-grow:       1;
    flex-basis:      0;
    justify-content: center;
    align-items:     center;

    @media (min-width: $breakpointMedium)
    {
        align-items: flex-end;
    }

    span
    {
        font-size:   14px;
        font-family: "Roboto", sans-serif;
        color:       $colorLightGray;
        white-space: nowrap;
        padding:     0 10px;
    }
}