@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.selectWrapper
{
    display:   flex;
    height:    50px;
    flex-grow: 1;
}

.selectHighlightedText
{
    background:  none;
    color:       $colorLightGray;
    font-weight: bold;
}

.addButtonContainer
{
    display: flex;
    margin:  5px;
    cursor:  pointer;
}