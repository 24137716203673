@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.container
{
    display:        flex;
    flex-direction: column;
    margin-bottom:  6px;

    @media (min-width: $breakpointMedium)
    {
        margin-bottom:  15px;
        flex-direction: row;
    }
}

.labelContainer
{
    display:       flex;
    flex-grow:     2;
    align-content: center;
    align-items:   center;
    min-height:    30px;

    &.empty
    {
        max-height: 0;
        min-height: inherit;
        overflow:   hidden;
    }

    @media (min-width: $breakpointMedium)
    {
        flex-basis: 0;
        min-height: 52px;

        &.empty
        {
            max-height: inherit;
            overflow:   visible;
        }
    }

    span
    {
        font-family:   $fontSourceRoboto;
        color:         $colorLightGray;
        padding-right: 10px;
    }
}

.inputContainer
{
    display:   flex;
    flex-grow: 5;
    flex-flow: wrap;

    @media (min-width: $breakpointMedium)
    {
        flex-basis: 0;
    }
}

.bigWhiteLabel
{
    span
    {
        font-size:   20px;
        color:       $colorWhite;
        font-weight: bold;
    }
}