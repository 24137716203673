@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.imageBackgroundContainer
{
    display:             flex;
    flex-direction:      column;
    flex:                1;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: 65% top;

    @media (max-width: $breakpointMediumMax)
    {
        background-image: none !important;
    }

    @media (min-width: $breakpointSmall)
    {
        background-position: 70% top;
    }

    @media (min-width: $breakpointMedium)
    {
        display:             initial;
        background-position: center top;
    }
}
