@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.headerContainer
{
    display:          flex;
    background-color: $colorBlack;
    justify-content:  space-between;
    height:           initial;
    flex-direction:   column-reverse;
    padding:          20px 20px;
    align-items:      initial;

    @media (min-width: $breakpointMedium)
    {
        padding:        0 120px;
        height:         106px;
        flex-direction: row;
        align-items:    center;
    }

    @media (min-width: $breakpointLarge)
    {
        height: 206px;
    }

    .headerTitle
    {
        color:          $colorWhite;
        font-size:      20px;
        font-weight:    bold;
        line-height:    1.6;
        letter-spacing: -0.24px;
        hyphens:        auto;
        word-break:     break-word;

        @media (min-width: $breakpointMedium)
        {
            font-size: 28px;
        }
    }

    .headerLeftContainer
    {
        display:     flex;
        align-items: center;

        @media (min-width: $breakpointMedium)
        {
            align-items: normal;
        }
    }

    .headerRightContainer
    {
        display: flex;
    }

    .headerLogo
    {
        height:  24px;
        padding: 10px;

        @media (min-width: $breakpointMedium)
        {
            padding: 0;
        }
    }
}
