@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.container
{
    margin: 6px 0 !important;

    @media (min-width: $breakpointMedium)
    {
        margin: 6px 5px !important;
    }
}

.selectWrapper
{
    display:        flex;
    flex-direction: row;
    width:          100%;
    height:         50px;
    margin:         0 0 20px 0;
    position:       relative;
    padding:        0 0 0 49px;
}

.selectHighlightedText
{
    background:  none;
    color:       $colorLightGray;
    font-weight: bold;
}

.dropDownIndicator
{
    width:        0;
    height:       0;
    margin-right: 10px;
    border-left:  7px solid transparent;
    border-right: 7px solid transparent;
    border-top:   7px solid $colorLightGray;
}