@import '../../../styles/colors';
@import '../../../styles/fonts';

.iconButton
{
    width:           20px;
    height:          20px;
    cursor:          pointer;
    display:         flex;
    justify-content: center;
}

.iconButtonBigIcon
{

}

.iconButtonRedRounded
{
    $size:         20px;

    width:         $size;
    height:        $size;
    border-radius: $size/2;
    background:    $colorRed;
    transition:    transform 0.1s linear;

    &:hover
    {
        transform: scale(1.04);
    }

    svg
    {
        height: 10px;
        width:  10px;
    }
}