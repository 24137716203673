@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.textInputWrapper
{
    $inputPadding:  12px;
    display:        flex;
    flex-direction: row;
    width:          100%;
    box-sizing:     border-box;
    min-height:     40px;
    position:       relative;
    margin:         6px 0;

    @media (min-width: $breakpointMedium)
    {
        margin: 6px 5px;
    }

    &:hover
    {
        input,
        textarea
        {
            border-color: $colorLightGray;
        }
    }

    input,
    textarea
    {
        font-family:      $fontSourceRoboto !important;
        flex:             1;
        outline:          none;
        transition:       border-color 0.1s linear;
        font-size:        16px;
        font-weight:      normal;
        padding:          0 $inputPadding;
        color:            $colorLightGray2;
        border-radius:    6px;
        background-color: $colorBlueDarkGray;
        border:           solid 1px $colorBlueDarkGray;

        &:hover,
        &:focus
        {
            border: solid 1px $colorLightGray;
        }

        &:read-only
        {
            border: solid 1px $colorBlueDarkGray;
            cursor: not-allowed;
        }

    }

    textarea
    {
        height:     101px;
        resize:     vertical;
        padding:    $inputPadding;
        min-height: 54px;
    }

    .unitBox
    {
        span
        {
            font-family: $fontSourceRoboto;
            font-size:   16px;
            color:       $colorLightGray2;
        }

        display:     flex;
        height:      100%;
        align-items: center;
        position:    absolute;
        right:       $inputPadding;
        user-select: none;
    }
}

