@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.headerButton
{
    padding: 10px;

    a
    {
        text-decoration: none;
        font-size:       22px;
        color:           $colorWhite;

        svg
        {
            margin: 0 5px 0 0;

            *
            {
                transition: fill 0.3s linear;
            }
        }

        &:hover
        {
            svg
            {
                *
                {
                    fill: $colorYellow;
                }
            }
        }
    }
}
