@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

$size: 30px;

.checkboxContainer
{
    position:            relative;
    display:             block;
    width:               $size;
    height:              $size;
    overflow:            hidden;
    margin-right:        20px;
    -webkit-user-select: none;
    -moz-user-select:    none;
    -ms-user-select:     none;
    user-select:         none;

    &.hidden
    {
        width:  0;
        margin: 0;
    }

    input
    {
        position: absolute;
        opacity:  0;
        cursor:   pointer;
        height:   0;
        width:    0;

        &:checked
        {
            + .checkbox
            {
                background-color: $colorGreenYellow;

                &::after
                {
                    color:   $colorWhite;
                    content: '\f00c';
                }
            }
        }
    }

    .checkbox
    {
        position:         absolute;
        top:              0;
        left:             0;
        width:            $size;
        height:           $size;
        box-sizing:       border-box;
        cursor:           pointer;
        border:           1px solid $colorGreenYellow;
        border-radius:    6px;
        background-color: $colorBlueDarkGray;

        &::after
        {
            display:         flex;
            align-items:     center;
            justify-content: center;
            font-family:     $fontAwesomeFree;
            font-weight:     900;
            font-size:       28px;
        }
    }
}