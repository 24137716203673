$fontAwesomeFree:       'Font Awesome 5 Free';
$fontSourceCourierNew:  source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
$fontSourceGeneralBody: -apple-system, 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
                        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$fontSourceRoboto:      -apple-system, 'Roboto', 'Oxygen', 'Ubuntu',
                        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

:export
{
    fontAwesomeFree:       $fontAwesomeFree;
    fontSourceCourierNew:  $fontSourceCourierNew;
    fontSourceGeneralBody: $fontSourceGeneralBody;
}