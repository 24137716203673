@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.container
{
    margin-top:     23px;
    display:        flex;
    flex-direction: column;

    @media (min-width: $breakpointMedium)
    {
        margin-top: 38px;
    }
}

.groupTitle
{
    font-size:     18px;
    font-weight:   bold;
    line-height:   1.11;
    color:         $colorWhite;
    margin-bottom: 6px;

    &.black
    {
        color: $colorDarkBlack !important;
    }
}

.submit
{
    align-items: flex-end;
}

