@import '../../../styles/colors';
@import '../../../styles/zIndexes';
@import '../../../styles/breakpoints';

.explosiveContainer
{
    &:last-child
    {
        margin-bottom: 0;
    }

    margin-bottom: 30px;
}

