@import '../../../styles/colors';
@import '../../../styles/zIndexes';
@import '../../../styles/breakpoints';

.container
{
    padding:        12px 10px 12px 32px;
    border-radius:  4px;
    font-size:      15px;
    border-width:   1px;
    border-style:   solid;
    margin:         0 0 20px 0;
    border-color:   $colorYellow;
    background:     $colorYellow;
    color:          $colorBlack;
    z-index:        1337;
    display:        flex;
    flex-direction: column;

    @media (min-width: $breakpointMedium)
    {
        align-items:    center;
        flex-direction: row;
        position:       absolute;
        right:          30px;
        top:            30px;
    }
}

.textContainer
{
    display: flex;
}

.buttonContainer
{
    padding: 10px 0;

    @media (min-width: $breakpointMedium)
    {
        padding:     0 20px;
        align-items: center;
        display:     flex;
    }
}