@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.container
{
    display:         flex;
    height:          216px;
    text-decoration: none;
    position:        relative;
    user-select:     none;

    @media (min-width: $breakpointMedium)
    {
        flex-grow:   1;
        flex-shrink: 1;
        flex-basis:  0;
    }

    .innerContainer
    {
        padding:             10px;
        display:             flex;
        flex-direction:      column;
        align-items:         center;
        justify-content:     center;
        background-position: center;
        background-size:     140% 100%;
        flex-grow:           1;
        flex-shrink:         1;
        flex-basis:          0;
        transition:          background-size .5s;

        &:hover
        {
            background-size: 160% 120%;
        }

        .title,
        .subTitle
        {
            font-size:   24px;
            font-weight: 900;
            color:       $colorWhite;
            text-align:  center;

            @media (min-width: $breakpointMedium)
            {
                font-size: 26px;
            }

            @media (min-width: $breakpointExtraLarge)
            {
                font-size: 32px;
            }
        }

        .highlightedSubTitle
        {
            color: $colorYellow;
        }
    }
}

