@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.container
{
    margin: 6px 0;

    @media (min-width: $breakpointMedium)
    {
        margin: 6px 5px;
    }
}

.optionsWrapper
{
    display:   flex;
    flex-flow: wrap;
}

.option
{
    font-size:        16px;
    cursor:           pointer;
    display:          flex;
    border:           1px solid $colorGreenYellow;
    height:           39px;
    width:            150px;
    align-content:    center;
    align-items:      center;
    justify-content:  center;
    background-color: $colorDarkGray;
    color:            $colorWhite50;
    font-weight:      300;
    user-select:      none;
    flex-grow:        1;
    flex-basis:       0;
}

.optionSelected
{
    font-weight:         bold;
    height:              36px;
    background-color:    $colorGreenYellow;
    border-width:        0;
    border-bottom-width: 4px;
    border-bottom-color: $colorYellow;
    color:               $colorWhite;
}