@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/zIndexes';

.printAllContainer
{
    margin-bottom: 30px;
}

.reportContainer
{
    display:          flex;
    margin-bottom:    20px;
    align-items:      flex-start;
    flex-direction:   column;
    border-radius:    6px;
    background-color: $colorDarkGray2;

    @media (min-width: $breakpointMedium)
    {
        padding:        20px;
        margin-bottom:  48px;
        flex-direction: row;
        align-items:    center;
    }

    .reportContainerCheckbox
    {
        input
        {
            margin-right: 20px;
        }
    }
}

.reportContent
{
    flex-direction: column;
    display:        flex;
    flex-grow:      6;

    @media (min-width: $breakpointMedium)
    {
        flex-basis: 0;
    }

    h2
    {
        color: $colorWhite;
    }

    .reportDate
    {
        color:         $colorLightGray2;
        font-size:     14px;
        margin-bottom: 14px;
    }
}

.reportOptions
{
    display:         flex;
    flex-grow:       2;
    justify-content: flex-start;
    margin-top:      10px;

    @media (min-width: $breakpointMedium)
    {
        margin-top: 0;
    }
}