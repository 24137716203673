@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.container
{
    display:        flex;
    margin-top:     120px;
    margin-bottom:  50px;
    flex-grow:      1;
    flex-shrink:    1;
    flex-direction: column;
    hyphens:        auto;

    @media (min-width: $breakpointMediumMin)
    {
        flex-direction: row;
    }
}

