@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.alertBox
{
    font-weight:   bold;
    font-size:     16px;
    color:         $colorRed;
    margin-top:    10px;
    margin-bottom: 10px;
}
